import * as React from 'react';
import {
  Datagrid,
  List,
  TextField,
  ListProps,
  FunctionField,
  BooleanField,
} from 'react-admin';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { FC } from 'react';
import { Link, FieldProps, Filter, TextInput } from 'react-admin';

import { ReactElement } from 'react';

const MultipleChoiceLinkField: FC<FieldProps> = (props) =>
  props.record ? (
    <Link
      to={`/content/multipleChoice/${props.record.id}`}
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {props?.record?.promptAudioFile ? (
        <VolumeUpIcon style={{ fontSize: 14, marginRight: 2 }} />
      ) : null}
      {props.record.prompt}
    </Link>
  ) : null;

const MultipleChoiceOptionsField: FC<FieldProps> = (props) =>
  props.record ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {props.record?.practice?.map((practice: any, index: number) => (
        <div key={practice.id} style={{}}>
          <Link to={`/content/practice/${practice.id}`}>{practice.word}</Link>
          {index !== props.record?.practice.length - 1 ? (
            <span>&nbsp;&nbsp;•&nbsp;&nbsp;</span>
          ) : null}
        </div>
      ))}
    </div>
  ) : null;

const MultipleChoiceFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Tags" source="tags" defaultValue="" />
  </Filter>
);

const MultipleChoiceList = (props: ListProps): ReactElement => (
  <List {...props} perPage={25} filters={<MultipleChoiceFilter />}>
    <Datagrid optimized>
      <MultipleChoiceLinkField label="Prompt" />
      <FunctionField
        render={(record: any) => record && record?.practice?.length}
        label="Num. Choices"
      />
      <TextField source="correctIndex" label="Correct Index" />
      <TextField label="Difficulty" source="difficulty" />
      <BooleanField source="onlyDisplayAudio" label="Only audio answers?" />
      <MultipleChoiceOptionsField label="options" />
    </Datagrid>
  </List>
);

export default MultipleChoiceList;
