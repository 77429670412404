/**
 * @generated SignedSource<<38bc7fc278184b60cc7e3134097dbaa5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DLPPlayground_Query$variables = {
  genParams: any;
  userID: string;
};
export type DLPPlayground_Query$data = {
  readonly playgroundCurriculum: {
    readonly curriculum: {
      readonly id: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"DLPPlayground_curriculum">;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"DLPPlayground_MergedSkills_playgroundCurriculum" | "DLPPlayground_ScoredSkills_playgroundCurriculum">;
  } | null | undefined;
};
export type DLPPlayground_Query = {
  response: DLPPlayground_Query$data;
  variables: DLPPlayground_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "genParams"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userID"
},
v2 = [
  {
    "kind": "Variable",
    "name": "genParams",
    "variableName": "genParams"
  },
  {
    "kind": "Variable",
    "name": "userID",
    "variableName": "userID"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assessmentScore",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quickWin",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "importance",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "speechaceCanScoreSkillWell",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Skill",
  "kind": "LinkedField",
  "name": "skill",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "score",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assessmentMinScore",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assessmentMaxScore",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLanguageRelevant",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "DLPPlaygroundUserSkill",
  "kind": "LinkedField",
  "name": "superseding",
  "plural": true,
  "selections": [
    (v11/*: any*/),
    (v4/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/),
    (v14/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Skill",
      "kind": "LinkedField",
      "name": "skill",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        (v5/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DLPPlayground_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DLPPlaygroundResult",
        "kind": "LinkedField",
        "name": "playgroundCurriculum",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DLPPlayground_ScoredSkills_playgroundCurriculum"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DLPPlayground_MergedSkills_playgroundCurriculum"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DLPCurriculum",
            "kind": "LinkedField",
            "name": "curriculum",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DLPPlayground_curriculum"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DLPPlayground_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DLPPlaygroundResult",
        "kind": "LinkedField",
        "name": "playgroundCurriculum",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DLPPlaygroundUserSkill",
            "kind": "LinkedField",
            "name": "userSkills",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DLPPlaygroundUserSkill",
            "kind": "LinkedField",
            "name": "mergedSkills",
            "plural": true,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v4/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DLPCurriculum",
            "kind": "LinkedField",
            "name": "curriculum",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DLPChapter",
                "kind": "LinkedField",
                "name": "chapters",
                "plural": true,
                "selections": [
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DLPUnit",
                    "kind": "LinkedField",
                    "name": "units",
                    "plural": true,
                    "selections": [
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DLPCurriculumItem",
                        "kind": "LinkedField",
                        "name": "items",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "dayNumber",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "optional",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "completedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Skill",
                            "kind": "LinkedField",
                            "name": "skill",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v6/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Video",
                            "kind": "LinkedField",
                            "name": "video",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Conversation",
                            "kind": "LinkedField",
                            "name": "conversation",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PracticeText",
                            "kind": "LinkedField",
                            "name": "practices",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "text",
                                "storageKey": null
                              },
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssessmentTaken",
                            "kind": "LinkedField",
                            "name": "assessment",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "averageScore",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "updatedAt",
                                "storageKey": null
                              },
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "76d7890a4fc54ea44f7134fad837ed2a",
    "id": null,
    "metadata": {},
    "name": "DLPPlayground_Query",
    "operationKind": "query",
    "text": "query DLPPlayground_Query(\n  $userID: ID!\n  $genParams: JSONObject!\n) {\n  playgroundCurriculum(userID: $userID, genParams: $genParams) {\n    ...DLPPlayground_ScoredSkills_playgroundCurriculum\n    ...DLPPlayground_MergedSkills_playgroundCurriculum\n    curriculum {\n      id\n      ...DLPPlayground_curriculum\n      nodeID\n    }\n  }\n}\n\nfragment DLPCurriculumItem_dlpCurriculumItem on DLPCurriculumItem {\n  type\n  optional\n  status\n  completedAt\n  skill {\n    id\n    name\n    nodeID\n  }\n  video {\n    title\n    nodeID\n  }\n  conversation {\n    name\n    nodeID\n  }\n  practices {\n    text\n    nodeID\n  }\n  assessment {\n    averageScore\n    updatedAt\n    nodeID\n  }\n}\n\nfragment DLPPlayground_MergedSkills_playgroundCurriculum on DLPPlaygroundResult {\n  mergedSkills {\n    skill {\n      id\n      nodeID\n    }\n    ...DLPPlayground_userSkill\n  }\n}\n\nfragment DLPPlayground_ScoredSkills_playgroundCurriculum on DLPPlaygroundResult {\n  userSkills {\n    assessmentScore\n    skill {\n      id\n      nodeID\n    }\n    ...DLPPlayground_userSkill\n  }\n}\n\nfragment DLPPlayground_curriculum on DLPCurriculum {\n  id\n  chapters {\n    number\n    units {\n      number\n      items {\n        id\n        dayNumber\n        ...DLPCurriculumItem_dlpCurriculumItem\n        nodeID\n      }\n    }\n  }\n}\n\nfragment DLPPlayground_userSkill on DLPPlaygroundUserSkill {\n  score\n  assessmentScore\n  assessmentMinScore\n  assessmentMaxScore\n  isLanguageRelevant\n  skill {\n    id\n    name\n    quickWin\n    importance\n    speechaceCanScoreSkillWell\n    nodeID\n  }\n  superseding {\n    ...DLPPlayground_userSkill_l7prJ\n  }\n}\n\nfragment DLPPlayground_userSkill_l7prJ on DLPPlaygroundUserSkill {\n  score\n  assessmentScore\n  assessmentMinScore\n  assessmentMaxScore\n  isLanguageRelevant\n  skill {\n    id\n    name\n    quickWin\n    importance\n    speechaceCanScoreSkillWell\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "f39b758b527c7b77667bc3826ff7d032";

export default node;
