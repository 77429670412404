import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useShowController } from 'react-admin';
// import humanizeString from 'humanize-string';
import { JsonField } from 'react-admin-json-view';

const STRESS_LEVELS: any = {
  0: 'Unstressed',
  1: 'Primary Stress',
  2: 'Secondary Stress',
};

const AudioLogShow = (props: any) => {
  const { record } = useShowController(props);
  const classes = useStyles();

  if (!record) return null;
  let parsedSpeechace: any = null;
  let requestId = null;
  let modelName = null;
  try {
    const { speechace, requestId: reqId, model_name } = record;
    parsedSpeechace = JSON.parse(speechace);
    requestId = reqId;
    modelName = model_name;
  } catch (err) {
    console.log('err', err);
    const { speechace } = record;
    return <div>{speechace}</div>;
  }

  const {
    fidelity_class,
    fluency,
    original_quality_score,
    quality_score,
    speechaceApiTime,
    scoringApiTime,
    urlToUse,
    word_score_list,
    word_intonation_list,
    adjustmentFactor,
    apiVersion,
    scoringFormulaVersion,
    detail_message, // Destructure detail_message, I am not sure whether this works
    short_message, // Destructure detail_message, I am not sure whether this works
  } = parsedSpeechace;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              Fidelity Class: {fidelity_class}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom align="right">
              Quality Score: {quality_score}% / Original:{' '}
              {original_quality_score}%
            </Typography>
            {adjustmentFactor && (
              <Typography variant="h6" gutterBottom align="right">
                Adjustment Factor: {adjustmentFactor}
              </Typography>
            )}
            <Typography
              variant="h6"
              gutterBottom
              align="right"
              style={{ textTransform: 'capitalize' }}
            >
              Model Name: {modelName}
            </Typography>
            <Typography variant="h6" gutterBottom align="right">
              API Version: {apiVersion || `v0.5`}
            </Typography>
            <Typography variant="h6" gutterBottom align="right">
              Audio Log Id: {record.id || `n/a`}
            </Typography>
            <Typography variant="h6" gutterBottom align="right">
              Request Id: {requestId || `n/a`}
            </Typography>
            <Typography variant="h6" gutterBottom align="right">
              Scoring Formula Version: {scoringFormulaVersion || `v1`}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.spacer}>&nbsp;</div>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom align="left">
              Word Score List
            </Typography>
            {word_score_list.map((word: any, index: number) => (
              <div
                key={`${index + 1}`}
                style={{ borderBottom: '1px solid black' }}
              >
                <Typography
                  key={`${index + 1}`}
                  variant="body1"
                  gutterBottom
                  align="left"
                  style={{ borderBottom: '1px solid black' }}
                >
                  <Box display="inline-block" fontWeight="fontWeightBold">
                    {word.word}
                  </Box>{' '}
                  ({Math.round(word.quality_score)}% /{' '}
                  {Math.round(word.original_quality_score)}%)
                </Typography>

                {word.phone_score_list.map((phone: any, phoneIndex: number) => (
                  <Typography
                    key={`${phoneIndex + 1}`}
                    variant="body1"
                    gutterBottom
                    align="left"
                  >
                    <Box display="inline-block" fontWeight="fontWeightBold">
                      {phone.phone}
                    </Box>{' '}
                    ({Math.round(phone.quality_score)}% /{' '}
                    {Math.round(phone.original_quality_score)}%)
                    {phone.sound_most_like &&
                      phone.sound_most_like !== phone.phone && (
                        <Typography>
                          Sounds Like: {phone.sound_most_like}
                        </Typography>
                      )}
                    {phone.predicted_stress_level !== undefined &&
                    phone.predicted_stress_level !== null ? (
                      <Typography>
                        Predicted Stress:{' '}
                        {STRESS_LEVELS[phone.predicted_stress_level]}
                      </Typography>
                    ) : null}
                    {phone.stress_level !== null ? (
                      <Typography>
                        Actual Stress: {STRESS_LEVELS[phone.stress_level]} -{' '}
                        {Math.round(phone.stress_score)}%
                      </Typography>
                    ) : null}
                  </Typography>
                ))}
              </div>
            ))}
          </Grid>
          <Grid item xs={6}>
            {fluency && (
              <>
                <Typography variant="h6" gutterBottom align="left">
                  Fluency Metrics
                </Typography>

                <JsonField
                  source="fluency.overall_metrics"
                  record={parsedSpeechace}
                  jsonString={false}
                  reactJsonOptions={{
                    name: 'Fluency Overall_metrics',
                    collapsed: true,
                    enableClipboard: true,
                    displayDataTypes: false,
                    sortKeys: true,
                    displayObjectSize: false,
                  }}
                />
              </>
            )}

            {word_intonation_list && (
              <>
                <Typography variant="h6" gutterBottom align="left">
                  Word Intonation
                </Typography>

                <JsonField
                  source="word_intonation_list"
                  record={parsedSpeechace}
                  jsonString={false}
                  reactJsonOptions={{
                    name: 'Word Intonation',
                    collapsed: true,
                    enableClipboard: true,
                    displayDataTypes: false,
                    sortKeys: true,
                    displayObjectSize: false,
                  }}
                />
              </>
            )}
          </Grid>
        </Grid>

        {(speechaceApiTime || scoringApiTime) && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom align="left">
                API Runtime: {speechaceApiTime ?? scoringApiTime} secs
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom align="left">
              URL: {urlToUse || 'https://api.speechace.co'}
            </Typography>
          </Grid>
        </Grid>
        {detail_message && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Detail Message: {detail_message}
              </Typography>
            </Grid>
          </Grid>
        )}
        {short_message && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Short Message: {short_message}
              </Typography>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default AudioLogShow;

const useStyles = makeStyles({
  root: { margin: 'auto' },
  spacer: { height: 20 },
  invoices: { margin: '10px 0' },
});
