/**
 * @generated SignedSource<<7ac655f63e94071aaa8a1cee8ceef225>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DLPExplorer_UserDisplay_Query$variables = {
  userID: string;
};
export type DLPExplorer_UserDisplay_Query$data = {
  readonly userByID: {
    readonly activeCurriculum: {
      readonly " $fragmentSpreads": FragmentRefs<"DLPExplorer_Curriculum_curriculum">;
    } | null | undefined;
    readonly city: string | null | undefined;
    readonly countryCode: string | null | undefined;
    readonly email: string | null | undefined;
    readonly firstName: string | null | undefined;
    readonly id: string | null | undefined;
    readonly languages: ReadonlyArray<{
      readonly englishName: string | null | undefined;
    }> | null | undefined;
    readonly lastName: string | null | undefined;
    readonly lastSeen: any | null | undefined;
    readonly region: string | null | undefined;
    readonly subStatus: string | null | undefined;
  } | null | undefined;
};
export type DLPExplorer_UserDisplay_Query = {
  response: DLPExplorer_UserDisplay_Query$data;
  variables: DLPExplorer_UserDisplay_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userID"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userID"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subStatus",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastSeen",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "region",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "englishName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DLPExplorer_UserDisplay_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "userByID",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Language",
            "kind": "LinkedField",
            "name": "languages",
            "plural": true,
            "selections": [
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DLPCurriculum",
            "kind": "LinkedField",
            "name": "activeCurriculum",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DLPExplorer_Curriculum_curriculum"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DLPExplorer_UserDisplay_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "userByID",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Language",
            "kind": "LinkedField",
            "name": "languages",
            "plural": true,
            "selections": [
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DLPCurriculum",
            "kind": "LinkedField",
            "name": "activeCurriculum",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DLPChapter",
                "kind": "LinkedField",
                "name": "chapters",
                "plural": true,
                "selections": [
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DLPUnit",
                    "kind": "LinkedField",
                    "name": "units",
                    "plural": true,
                    "selections": [
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DLPCurriculumItem",
                        "kind": "LinkedField",
                        "name": "items",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "dayNumber",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "optional",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "completedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Skill",
                            "kind": "LinkedField",
                            "name": "skill",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v14/*: any*/),
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Video",
                            "kind": "LinkedField",
                            "name": "video",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Conversation",
                            "kind": "LinkedField",
                            "name": "conversation",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PracticeText",
                            "kind": "LinkedField",
                            "name": "practices",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "text",
                                "storageKey": null
                              },
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssessmentTaken",
                            "kind": "LinkedField",
                            "name": "assessment",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "averageScore",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "updatedAt",
                                "storageKey": null
                              },
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a4d856ab387b52e3ea800b0057bb9b71",
    "id": null,
    "metadata": {},
    "name": "DLPExplorer_UserDisplay_Query",
    "operationKind": "query",
    "text": "query DLPExplorer_UserDisplay_Query(\n  $userID: ID!\n) {\n  userByID(id: $userID) {\n    id\n    email\n    firstName\n    lastName\n    subStatus\n    lastSeen\n    region\n    city\n    countryCode\n    languages {\n      englishName\n      nodeID\n    }\n    activeCurriculum {\n      ...DLPExplorer_Curriculum_curriculum\n      nodeID\n    }\n    nodeID\n  }\n}\n\nfragment DLPCurriculumItem_dlpCurriculumItem on DLPCurriculumItem {\n  type\n  optional\n  status\n  completedAt\n  skill {\n    id\n    name\n    nodeID\n  }\n  video {\n    title\n    nodeID\n  }\n  conversation {\n    name\n    nodeID\n  }\n  practices {\n    text\n    nodeID\n  }\n  assessment {\n    averageScore\n    updatedAt\n    nodeID\n  }\n}\n\nfragment DLPExplorer_Curriculum_curriculum on DLPCurriculum {\n  id\n  chapters {\n    number\n    units {\n      number\n      items {\n        id\n        dayNumber\n        ...DLPCurriculumItem_dlpCurriculumItem\n        nodeID\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d5be1ca5121e499ad214bf876892ddfa";

export default node;
