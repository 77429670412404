/**
 * @generated SignedSource<<b487663e45ef070df892f5ffdf38e06d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserLevel = "ADVANCED" | "BEGINNER" | "INTERMEDIATE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DLPExplorer_User_user$data = {
  readonly activeCurriculum: {
    readonly lastCompletedItem: {
      readonly order: number | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly createdAt: any | null | undefined;
  readonly email: string | null | undefined;
  readonly firstName: string | null | undefined;
  readonly id: string | null | undefined;
  readonly isSubscriber: boolean | null | undefined;
  readonly languages: ReadonlyArray<{
    readonly englishName: string | null | undefined;
  }> | null | undefined;
  readonly lastAssessmentTaken: {
    readonly id: string | null | undefined;
  } | null | undefined;
  readonly lastName: string | null | undefined;
  readonly latestModel: string | null | undefined;
  readonly level: UserLevel | null | undefined;
  readonly subStatus: string | null | undefined;
  readonly " $fragmentType": "DLPExplorer_User_user";
};
export type DLPExplorer_User_user$key = {
  readonly " $data"?: DLPExplorer_User_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"DLPExplorer_User_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DLPExplorer_User_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSubscriber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestModel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "level",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Language",
      "kind": "LinkedField",
      "name": "languages",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "englishName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DLPCurriculum",
      "kind": "LinkedField",
      "name": "activeCurriculum",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DLPCurriculumItem",
          "kind": "LinkedField",
          "name": "lastCompletedItem",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "order",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssessmentTaken",
      "kind": "LinkedField",
      "name": "lastAssessmentTaken",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "e008dda0565390abffce8c7a75db5f46";

export default node;
