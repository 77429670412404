/**
 * @generated SignedSource<<2425bcca5bc5bfa1de7b1bae8afdbdc7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillEditQuery$variables = {
  id?: string | null | undefined;
};
export type SkillEditQuery$data = {
  readonly raSkill: {
    readonly " $fragmentSpreads": FragmentRefs<"SkillFormAssociatedPhonemes_phoneme" | "SkillFormAssociatedSkills_skill" | "SkillLessonsEditor_skill">;
  } | null | undefined;
};
export type SkillEditQuery = {
  response: SkillEditQuery$data;
  variables: SkillEditQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberPracticeSets",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
},
v8 = [
  (v2/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v10 = {
  "alias": "practiceTexts",
  "args": null,
  "concreteType": "PracticeText",
  "kind": "LinkedField",
  "name": "allPracticeTexts",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coachSample",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "aiCoachSample",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSentence",
      "storageKey": null
    },
    (v7/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Skill",
        "kind": "LinkedField",
        "name": "raSkill",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SkillLessonsEditor_skill"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SkillFormAssociatedSkills_skill"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SkillFormAssociatedPhonemes_phoneme"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SkillEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Skill",
        "kind": "LinkedField",
        "name": "raSkill",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SkillLesson",
            "kind": "LinkedField",
            "name": "skillLessons",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "order",
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Conversation",
                "kind": "LinkedField",
                "name": "conversation",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Video",
                "kind": "LinkedField",
                "name": "video",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v9/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillLesson",
                "kind": "LinkedField",
                "name": "linkedSkillLesson",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Video",
                    "kind": "LinkedField",
                    "name": "video",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Conversation",
                    "kind": "LinkedField",
                    "name": "conversation",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v10/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillLesson",
                    "kind": "LinkedField",
                    "name": "linkedSkillLesson",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Video",
                        "kind": "LinkedField",
                        "name": "video",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Conversation",
                        "kind": "LinkedField",
                        "name": "conversation",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v10/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Skill",
            "kind": "LinkedField",
            "name": "supersedingSkills",
            "plural": true,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Skill",
            "kind": "LinkedField",
            "name": "childSkills",
            "plural": true,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Phoneme",
            "kind": "LinkedField",
            "name": "phonemes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ipa",
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ee3211b04e534887c08c9ce77116d0e1",
    "id": null,
    "metadata": {},
    "name": "SkillEditQuery",
    "operationKind": "query",
    "text": "query SkillEditQuery(\n  $id: ID\n) {\n  raSkill(id: $id) {\n    ...SkillLessonsEditor_skill\n    ...SkillFormAssociatedSkills_skill\n    ...SkillFormAssociatedPhonemes_phoneme\n    nodeID\n  }\n}\n\nfragment SkillFormAssociatedPhonemes_phoneme on Skill {\n  id\n  phonemes {\n    id\n    ipa\n    nodeID\n  }\n}\n\nfragment SkillFormAssociatedSkills_skill on Skill {\n  id\n  supersedingSkills {\n    id\n    name\n    nodeID\n  }\n  childSkills {\n    id\n    name\n    nodeID\n  }\n}\n\nfragment SkillLessonsEditor_skill on Skill {\n  id\n  skillLessons {\n    id\n    order\n    ...SkillLessonsEditor_skillLesson\n    nodeID\n  }\n}\n\nfragment SkillLessonsEditor_skillLesson on SkillLesson {\n  id\n  type\n  active\n  numberPracticeSets\n  conversation {\n    id\n    name\n    nodeID\n  }\n  video {\n    id\n    title\n    nodeID\n  }\n  linkedSkillLesson {\n    id\n    video {\n      title\n      nodeID\n    }\n    conversation {\n      name\n      nodeID\n    }\n    numberPracticeSets\n    practiceTexts: allPracticeTexts {\n      id\n      text\n      coachSample\n      aiCoachSample\n      isSentence\n      nodeID\n    }\n    ...SkillLessonsEditor_skillLesson_CAyYJ\n    nodeID\n  }\n  practiceTexts: allPracticeTexts {\n    id\n    text\n    coachSample\n    aiCoachSample\n    isSentence\n    nodeID\n  }\n}\n\nfragment SkillLessonsEditor_skillLesson_CAyYJ on SkillLesson {\n  id\n  type\n  active\n  numberPracticeSets\n  conversation {\n    id\n    name\n    nodeID\n  }\n  video {\n    id\n    title\n    nodeID\n  }\n  linkedSkillLesson {\n    id\n    video {\n      title\n      nodeID\n    }\n    conversation {\n      name\n      nodeID\n    }\n    numberPracticeSets\n    practiceTexts: allPracticeTexts {\n      id\n      text\n      coachSample\n      aiCoachSample\n      isSentence\n      nodeID\n    }\n    nodeID\n  }\n  practiceTexts: allPracticeTexts {\n    id\n    text\n    coachSample\n    aiCoachSample\n    isSentence\n    nodeID\n  }\n}\n"
  }
};
})();

(node as any).hash = "1d0d0e218f44fc60c13c95b7f204a01f";

export default node;
