/**
 * @generated SignedSource<<0785aa2365832a89883a2453da54ec3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DLPExplorerUsersSelectPaginationQuery$variables = {
  count?: number | null | undefined;
  cursor?: string | null | undefined;
  languages?: ReadonlyArray<string> | null | undefined;
  latestModel?: string | null | undefined;
  search?: string | null | undefined;
  subStatuses?: ReadonlyArray<string> | null | undefined;
};
export type DLPExplorerUsersSelectPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DLPExplorer_UsersSelect_users">;
};
export type DLPExplorerUsersSelectPaginationQuery = {
  response: DLPExplorerUsersSelectPaginationQuery$data;
  variables: DLPExplorerUsersSelectPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 30,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "languages"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "latestModel"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subStatuses"
  }
],
v1 = {
  "kind": "Variable",
  "name": "languages",
  "variableName": "languages"
},
v2 = {
  "kind": "Variable",
  "name": "latestModel",
  "variableName": "latestModel"
},
v3 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v4 = {
  "kind": "Variable",
  "name": "subStatuses",
  "variableName": "subStatuses"
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "CREATED_AT"
  },
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeID",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DLPExplorerUsersSelectPaginationQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "DLPExplorer_UsersSelect_users"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DLPExplorerUsersSelectPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "QueryUsersForDLPExplorerConnection",
        "kind": "LinkedField",
        "name": "usersForDLPExplorer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "QueryUsersForDLPExplorerConnectionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isSubscriber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latestModel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "level",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Language",
                    "kind": "LinkedField",
                    "name": "languages",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "englishName",
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DLPCurriculum",
                    "kind": "LinkedField",
                    "name": "activeCurriculum",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DLPCurriculumItem",
                        "kind": "LinkedField",
                        "name": "lastCompletedItem",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "order",
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssessmentTaken",
                    "kind": "LinkedField",
                    "name": "lastAssessmentTaken",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "filters": [
          "orderBy",
          "languages",
          "subStatuses",
          "search",
          "latestModel"
        ],
        "handle": "connection",
        "key": "DLPExplorer_usersForDLPExplorer",
        "kind": "LinkedHandle",
        "name": "usersForDLPExplorer"
      }
    ]
  },
  "params": {
    "cacheID": "2c41a0358d397b4812fec8f3f7a44125",
    "id": null,
    "metadata": {},
    "name": "DLPExplorerUsersSelectPaginationQuery",
    "operationKind": "query",
    "text": "query DLPExplorerUsersSelectPaginationQuery(\n  $count: Int = 30\n  $cursor: String\n  $languages: [String!]\n  $latestModel: String\n  $search: String\n  $subStatuses: [String!]\n) {\n  ...DLPExplorer_UsersSelect_users_36FUL7\n}\n\nfragment DLPExplorer_User_user on User {\n  id\n  email\n  firstName\n  lastName\n  createdAt\n  subStatus\n  isSubscriber\n  latestModel\n  level\n  languages {\n    englishName\n    nodeID\n  }\n  activeCurriculum {\n    lastCompletedItem {\n      order\n      nodeID\n    }\n    nodeID\n  }\n  lastAssessmentTaken {\n    id\n    nodeID\n  }\n}\n\nfragment DLPExplorer_UsersSelect_users_36FUL7 on Query {\n  usersForDLPExplorer(first: $count, after: $cursor, orderBy: CREATED_AT, languages: $languages, subStatuses: $subStatuses, search: $search, latestModel: $latestModel) {\n    edges {\n      node {\n        id\n        ...DLPExplorer_User_user\n        nodeID\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d0bc21584c2488bf8615246fc5dddbc8";

export default node;
