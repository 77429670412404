import * as React from 'react';
import { Admin, Resource } from 'react-admin';

import { AdsTrackingPlayground } from './adTracking';
import Assessment from './assessment';
import AssessmentsTaken from './assessmentstaken';
import AudioLogs from './audiologs';
// import simpleRestProvider from 'ra-data-simple-rest';
import authProvider from './authProvider';
import CancellationReport from './cancellationreport';
import Coach from './coach';
import CoachQuips from './coachQuips';
import ContactUs from './contactus';
import Conversation from './conversation';
import Curriculum from './curriculum';
import customRoutes from './customRoutes';
import DailyLesson from './dailylesson';
import { Dashboard } from './dashboard';
import customDataProvider from './dataProvider';
import EmailLogs from './emaillogs';
import { Engagement } from './engagement';
import GenerativeConversation from './gen_conv';
import GoalTemplate from './gen_conv/goal_template';
import TopicConfig from './gen_conv/topic_config';
import IssueReport from './issuereport';
import { Layout } from './layout';
import Lesson from './lesson';
import MouthDiagram from './mouthDiagram';
import MouthDiagramSet from './mouthDiagramSet';
import MultipleChoice from './multipleChoice';
import NotificationLogs from './notificationlogs';
import Phoneme from './phoneme';
import Practice from './practice';
import ProvisionalUsers from './provisionalusers';
import Rapidfire from './rapidfire';
import ReferralCodes from './referrals/codes';
import ReferralCodeRedemptions from './referrals/redemptions';
// import { ReferralsAdmin } from './referrals';
import Skills from './skills';
import Song from './song';
import SongsTaken from './songstaken';
import TutorialConfig from './tutorialConfig';
import UniqueCodes from './uniquecodes';
import Unit from './unit';
import UserImprovement from './userimprovement';
import Users from './users';
import UserStats from './userstats';
import Video from './video';
import VideoGroups from './videoGroups';
import VideoFeedback from './videofeedback';
import Words from './words';

const App = () => (
  <Admin
    title="BoldVoice CMS"
    dataProvider={customDataProvider}
    authProvider={authProvider}
    layout={Layout}
    dashboard={Dashboard}
    disableTelemetry
    customRoutes={customRoutes}
  >
    <Resource name="engagement" list={Engagement} />
    <Resource name="ads-tracking" list={AdsTrackingPlayground} />
    {/* <Resource name="referrals" list={ReferralsAdmin} /> */}
    <Resource
      name="users"
      options={{ label: 'Users' }}
      list={Users.list}
      edit={Users.edit}
      create={Users.create}
      icon={Users.icon}
    />
    <Resource
      name="audiologs"
      options={{ label: 'Audio Logs' }}
      list={AudioLogs.list}
      icon={AudioLogs.icon}
    />
    <Resource
      name="assessmentstaken"
      options={{ label: 'Assessments Taken' }}
      list={AssessmentsTaken.list}
      icon={AssessmentsTaken.icon}
    />
    <Resource
      name="songstaken"
      options={{ label: 'Songs Sung' }}
      list={SongsTaken.list}
      icon={SongsTaken.icon}
    />
    <Resource
      name="content/curriculum"
      options={{ label: 'Curriculums' }}
      list={Curriculum.list}
      edit={Curriculum.edit}
      create={Curriculum.create}
      icon={Curriculum.icon}
    />
    <Resource
      name="content/unit"
      options={{ label: 'Units' }}
      list={Unit.list}
      edit={Unit.edit}
      create={Unit.create}
      icon={Unit.icon}
    />
    <Resource
      name="content/lesson"
      options={{ label: 'Lessons' }}
      list={Lesson.list}
      edit={Lesson.edit}
      create={Lesson.create}
      icon={Lesson.icon}
    />
    <Resource
      name="content/dailylesson"
      options={{ label: 'Daily Goals' }}
      list={DailyLesson.list}
      icon={DailyLesson.icon}
    />
    <Resource
      name="content/video"
      options={{ label: 'Videos' }}
      list={Video.list}
      edit={Video.edit}
      create={Video.create}
      icon={Video.icon}
    />
    <Resource
      name="content/practice"
      options={{ label: 'Practices' }}
      list={Practice.list}
      edit={Practice.edit}
      create={Practice.create}
      icon={Practice.icon}
    />
    <Resource
      name="content/multiplechoice"
      options={{ label: 'Multiple Choice' }}
      list={MultipleChoice.list}
      edit={MultipleChoice.edit}
      create={MultipleChoice.create}
      icon={MultipleChoice.icon}
    />
    <Resource
      name="content/rapidfire"
      options={{ label: 'Rapid Fire' }}
      list={Rapidfire.list}
      edit={Rapidfire.edit}
      create={Rapidfire.create}
      icon={Rapidfire.icon}
    />
    <Resource name="content/challenges" options={{ label: 'Challenges' }} />
    <Resource
      name="content/practice_set"
      options={{ label: 'Practice Sets' }}
    />
    <Resource
      name="content/assessment"
      options={{ label: 'Assessments' }}
      list={Assessment.list}
      edit={Assessment.edit}
      create={Assessment.create}
      icon={Assessment.icon}
    />
    <Resource
      name="content/song"
      options={{ label: 'Songs' }}
      list={Song.list}
      edit={Song.edit}
      create={Song.create}
      icon={Song.icon}
    />
    <Resource
      name="content/phoneme"
      options={{ label: 'Phonemes' }}
      list={Phoneme.list}
      edit={Phoneme.edit}
      create={Phoneme.create}
      icon={Phoneme.icon}
    />
    <Resource
      name="content/mouthDiagram"
      options={{ label: 'Mouth Diagrams' }}
      list={MouthDiagram.list}
      edit={MouthDiagram.edit}
      create={MouthDiagram.create}
      icon={MouthDiagram.icon}
    />

    <Resource
      name="content/mouthDiagramSet"
      options={{ label: 'Mouth Diagrams Sets' }}
      list={MouthDiagramSet.list}
      edit={MouthDiagramSet.edit}
      create={MouthDiagramSet.create}
      icon={MouthDiagramSet.icon}
    />

    <Resource
      name="content/generative_conversation/topic_config"
      options={{ label: 'Gen Conv Topic Config' }}
      list={TopicConfig.list}
      edit={TopicConfig.edit}
      create={TopicConfig.create}
      icon={TopicConfig.icon}
    />

    <Resource
      name="content/generative_conversation/goal_template"
      options={{ label: 'Gen Conv Goal Templates' }}
      list={GoalTemplate.list}
      edit={GoalTemplate.edit}
      create={GoalTemplate.create}
      icon={GoalTemplate.icon}
    />

    <Resource
      name="logs/generative_conversation"
      options={{ label: 'Generative Conversations' }}
      list={GenerativeConversation.list}
      icon={GenerativeConversation.icon}
    />
    <Resource
      name="content/conversation"
      options={{ label: 'Conversations' }}
      list={Conversation.list}
      edit={Conversation.edit}
      create={Conversation.create}
      icon={Conversation.icon}
    />
    <Resource
      name="coach"
      options={{ label: 'Coaches' }}
      list={Coach.list}
      edit={Coach.edit}
      create={Coach.create}
      icon={Coach.icon}
    />
    <Resource
      name="content/quips"
      options={{ label: 'Coach Quips' }}
      list={CoachQuips.list}
      edit={CoachQuips.edit}
      create={CoachQuips.create}
      icon={CoachQuips.icon}
    />
    <Resource
      name="content/tutorial_config"
      options={{ label: 'Tutorial Config' }}
      list={TutorialConfig.list}
      edit={TutorialConfig.edit}
      create={TutorialConfig.create}
      icon={TutorialConfig.icon}
    />
    <Resource
      name="videofeedback"
      options={{ label: 'Video Feedback' }}
      list={VideoFeedback.list}
      icon={VideoFeedback.icon}
    />
    <Resource
      name="provisional_users"
      options={{ label: 'Provisional Users' }}
      list={ProvisionalUsers.list}
      edit={ProvisionalUsers.edit}
      icon={ProvisionalUsers.icon}
    />
    <Resource
      name="user_stats"
      options={{ label: 'User Stats' }}
      list={UserStats.list}
      edit={UserStats.edit}
      icon={UserStats.icon}
    />
    <Resource
      name="logs/referral_codes"
      options={{ label: 'Referral Codes' }}
      list={ReferralCodes.list}
      edit={ReferralCodes.edit}
      create={ReferralCodes.create}
      icon={ReferralCodes.icon}
    />
    <Resource
      name="logs/referral_code_redemptions"
      options={{ label: 'Redemptions' }}
      list={ReferralCodeRedemptions.list}
      icon={ReferralCodeRedemptions.icon}
    />
    <Resource
      name="contact_us"
      options={{ label: 'Contact Us' }}
      list={ContactUs.list}
      icon={ContactUs.icon}
    />
    <Resource
      name="issue_report"
      options={{ label: 'Issue Reports' }}
      list={IssueReport.list}
      icon={IssueReport.icon}
    />
    <Resource
      name="cancellation_report"
      options={{ label: 'Cancellation Reports' }}
      list={CancellationReport.list}
      icon={CancellationReport.icon}
    />

    <Resource
      name="emaillogs"
      options={{ label: 'Email Logs' }}
      list={EmailLogs.list}
      icon={EmailLogs.icon}
    />
    <Resource
      name="notificationlogs"
      options={{ label: 'Notification Logs' }}
      list={NotificationLogs.list}
      icon={NotificationLogs.icon}
    />
    <Resource
      name="uniquecodes"
      options={{ label: 'Unique Codes' }}
      list={UniqueCodes.list}
      icon={UniqueCodes.icon}
    />
    <Resource
      name="userimprovement"
      options={{ label: 'User Improvement' }}
      list={UserImprovement.list}
      icon={UserImprovement.icon}
    />

    <Resource
      name="g/skills"
      options={{ label: 'Skills' }}
      list={Skills.list}
      edit={Skills.edit}
      create={Skills.create}
    />
    <Resource
      name="g/videos"
      options={{ label: 'Video Groups' }}
      list={VideoGroups.list}
      edit={VideoGroups.edit}
      create={VideoGroups.create}
    />
    <Resource
      name="g/words"
      options={{ label: 'Words' }}
      list={Words.list}
      edit={Words.edit}
    />
  </Admin>
);

export default App;
