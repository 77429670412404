import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  required,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  NumberInput,
} from 'react-admin';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import { matchSuggestion } from './MultipleChoiceEdit';
import LessonPracticeInput from '../components/LessonPracticeInput';

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  number: { display: 'block' },
};

const useStyles = makeStyles(styles);

const LessonCreate: FC<CreateProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Create {...props}>
      <SimpleForm autoComplete="off">
        <TextInput
          autoFocus
          source="prompt"
          label="Prompt"
          className={classes.name}
          validate={requiredValidate}
          fullWidth
        />
        <TextInput
          autoFocus
          source="promptAudioFile"
          label="Question Prompt Audio"
          fullWidth
          helperText="Optional"
        />
        <ReferenceArrayInput
          filterToQuery={(searchText: any) => ({ word: searchText })}
          label="Practice Items"
          source="practiceId"
          reference="content/practice"
          fullWidth
          filter={{ isUserGeneratedContent: false }}
        >
          <LessonPracticeInput showDifficulties={false}>
            <AutocompleteArrayInput
              debounce={750}
              optionValue="id"
              optionText={(record: any) =>
                `${record?.word} ${
                  record?.coachSample ? ` ✅` : ` ❌`
                } (id: ${record?.id?.slice(0, 4)})`
              }
              allowDuplicates
              matchSuggestion={(filterValue: string, choice: any) =>
                matchSuggestion(filterValue, choice, 'word')
              }
            />
          </LessonPracticeInput>
        </ReferenceArrayInput>
        <NumberInput
          fullWidth
          autoFocus
          source="correctIndex"
          label="Correct Index (start with 0)"
          className={classes.name}
          validate={requiredValidate}
        />
      </SimpleForm>
    </Create>
  );
};

const requiredValidate = [required()];

export default LessonCreate;
