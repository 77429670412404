import { Box, Card, CardContent } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import * as React from 'react';
import { FC } from 'react';
import {
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  FormWithRedirect,
  NullableBooleanInput,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
  required,
} from 'react-admin';

const QuipTitle: FC<FieldProps> = ({ record }) =>
  record ? (
    <span>
      Quip: &quot;
      {record.blurb}&quot;
    </span>
  ) : null;

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  photo: { display: 'inline-block' },
  spacing: { marginRight: 10 },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
  button: {
    marginRight: 10,
  },
};

const useStyles = makeStyles(styles);

const QuipForm = (props: any) => {
  const classes = useStyles(props);
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box>
                <TextInput
                  fullWidth
                  source="blurb"
                  label="Blurb"
                  className={classes.name}
                  autoFocus
                  multiline
                />
                <ReferenceInput
                  label="Coach Id"
                  source="coachId"
                  reference="coach"
                  validate={[required()]}
                  fullWidth
                  autoFocus
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              </Box>
              <Box>
                <TextInput
                  fullWidth
                  autoFocus
                  source="audioFile"
                  label="Audio File"
                  className={classes.name}
                />
              </Box>
              <Box>
                <NumberInput
                  fullWidth
                  autoFocus
                  source="stateKey"
                  label="State Key"
                  className={classes.name}
                />
              </Box>
              <Box>
                <NumberInput
                  fullWidth
                  autoFocus
                  source="nestedQuipOrder"
                  label="Nested Quip Order (starts with 0)"
                  className={classes.name}
                />
              </Box>
              <Box>
                <div>
                  Sentence Only: Select Yes. Words Only: Select No. Applicable
                  to All: Select Empty Option.
                </div>
                <NullableBooleanInput
                  fullWidth
                  style={{ minWidth: 200 }}
                  source="isForSentence"
                  label="Is for Sentence"
                  className={classes.name}
                />
              </Box>
              <Box>
                <div>
                  *If language is empty, then the quip will be used for all
                  langugaes
                </div>
                <TextInput label="Language" source="language" />
              </Box>

              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                undoable={false}
                invalid={formProps.invalid}
                handleSubmit={formProps.handleSubmit}
                saving={formProps.saving}
                resource="content/quips"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  <SaveButton
                    label="Save & Continue"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect="edit"
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />

                  <SaveButton
                    label="Save & Exit"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect={formProps.redirect}
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />
                </div>

                {formProps.record &&
                  typeof formProps.record.id !== 'undefined' && (
                    <DeleteButton
                      basePath={formProps.basePath}
                      record={formProps.record}
                      resource={formProps.resource}
                      undoable={formProps.undoable}
                      mutationMode={formProps.mutationMode}
                    />
                  )}
              </Toolbar>
            </CardContent>
          </form>
        </Card>
      )}
    />
  );
};

const QuipEdit: FC<EditProps> = (props) => (
  <Edit undoable={false} title={<QuipTitle />} {...props}>
    <QuipForm />
  </Edit>
);

export default QuipEdit;
