import LibraryAddCheckOutlined from '@material-ui/icons/LibraryAddCheckOutlined';

import MultipleChoiceList from './MultipleChoiceList';
import MultipleChoiceEdit from './MultipleChoiceEdit';
import MultipleChoiceCreate from './MultipleChoiceCreate';

export default {
  list: MultipleChoiceList,
  edit: MultipleChoiceEdit,
  icon: LibraryAddCheckOutlined,
  create: MultipleChoiceCreate,
};
