/**
 * @generated SignedSource<<2e8742f0b8f4f95e539d9b6d049f46b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DLPCurriculumItemStatus = "COMPLETED" | "IN_PROGRESS" | "NOT_STARTED" | "%future added value";
export type DLPCurriculumItemType = "AI_CHAT" | "ASSESSMENT" | "CONVERSATION" | "FOCUS_WORD" | "IDIOM" | "PRACTICE" | "QUIZ" | "VIDEO" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DLPCurriculumItem_dlpCurriculumItem$data = {
  readonly assessment: {
    readonly averageScore: number | null | undefined;
    readonly updatedAt: any | null | undefined;
  } | null | undefined;
  readonly completedAt: any | null | undefined;
  readonly conversation: {
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly optional: boolean | null | undefined;
  readonly practices: ReadonlyArray<{
    readonly text: string | null | undefined;
  }> | null | undefined;
  readonly skill: {
    readonly id: string | null | undefined;
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly status: DLPCurriculumItemStatus | null | undefined;
  readonly type: DLPCurriculumItemType | null | undefined;
  readonly video: {
    readonly title: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "DLPCurriculumItem_dlpCurriculumItem";
};
export type DLPCurriculumItem_dlpCurriculumItem$key = {
  readonly " $data"?: DLPCurriculumItem_dlpCurriculumItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"DLPCurriculumItem_dlpCurriculumItem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DLPCurriculumItem_dlpCurriculumItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optional",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Skill",
      "kind": "LinkedField",
      "name": "skill",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Video",
      "kind": "LinkedField",
      "name": "video",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Conversation",
      "kind": "LinkedField",
      "name": "conversation",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PracticeText",
      "kind": "LinkedField",
      "name": "practices",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssessmentTaken",
      "kind": "LinkedField",
      "name": "assessment",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "averageScore",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DLPCurriculumItem",
  "abstractKey": null
};
})();

(node as any).hash = "b84d9a4516a8ce0bdb1168e953c75d2c";

export default node;
