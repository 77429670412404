import { Box, Card, CardContent } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import * as React from 'react';
import { FC } from 'react';
import {
  AutocompleteArrayInput,
  BooleanInput,
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  FormWithRedirect,
  ReferenceArrayInput,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
} from 'react-admin';

import LessonPracticeInput, {
  difficultyList,
} from '../components/LessonPracticeInput';
import AudioField from '../practice/AudioField';

const categoriesList = [
  {
    id: 'phonemic_awareness',
    value: 'phonemic_awareness',
    name: 'Phonemic Awareness',
  },
  {
    id: 'fundamentals',
    value: 'fundamentals',
    name: 'Fundamentals',
  },
];

const MultipleChoiceTitle: FC<FieldProps> = ({ record }) =>
  record ? (
    <span>
      Multiple Choice &quot;
      {record.prompt}&quot;
    </span>
  ) : null;

export const styles: Styles<Theme, any> = {
  name: { display: 'inline' },
  photo: { display: 'inline-block' },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
  button: {
    marginRight: 10,
  },
};

const useStyles = makeStyles(styles);

const escapeRegExp = (value: any) =>
  value ? value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : ''; // $& means the whole matched string

export const matchSuggestion = (
  filterValue: string,
  choice: any,
  property: string,
) => {
  const isStrictSearch =
    filterValue.charAt(0) === `"` &&
    filterValue.charAt(filterValue.length - 1) === `"`;
  if (isStrictSearch) {
    if (
      choice?.[property]?.toLowerCase() ===
      filterValue.replaceAll(`"`, '')?.toLowerCase()
    ) {
      return true;
    }
    return false;
  }
  if (choice?.[property]?.match(new RegExp(escapeRegExp(filterValue), 'i'))) {
    return true;
  }
  return false;
};

const MultipleChoiceForm = (props: any) => {
  const classes = useStyles(props);
  console.log('main record', props.record);

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display="flex" flexDirection="row" alignItems="flex-start">
                <Box flex={1}>
                  <TextInput
                    autoFocus
                    source="prompt"
                    label="Question Prompt"
                    fullWidth
                    helperText={false}
                  />
                  <TextInput
                    autoFocus
                    source="promptAudioFile"
                    label="Question Prompt Audio"
                    fullWidth
                    helperText="Optional"
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="flex-start">
                <Box flex={1}>
                  <AudioField sortBy="promptAudioFile" />
                  <TextInput
                    source="correctIndex"
                    label="Correct Answer Index"
                    fullWidth
                    helperText={false}
                  />
                  <TextInput
                    source="explainataion"
                    label="Answer Explainataion"
                    fullWidth
                    helperText={false}
                  />
                  <SelectInput
                    choices={difficultyList}
                    source="difficulty"
                    label="Difficulty"
                    fullWidth
                    helperText={false}
                  />
                  <BooleanInput
                    source="onlyDisplayAudio"
                    label="Only Display Audio?"
                    helperText={false}
                  />
                  <SelectInput
                    resettable
                    choices={categoriesList}
                    source="category"
                    label="Question Category"
                    style={{ width: '30%' }}
                    helperText={false}
                  />
                  <TextInput
                    source="secondaryText"
                    label="Title secondary Text (omit for now)"
                    fullWidth
                    helperText={false}
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="flex-start">
                <Box flex={1} paddingRight={10}>
                  <ReferenceArrayInput
                    filterToQuery={(searchText: any) => ({ word: searchText })}
                    label="Practice Items"
                    source="practiceId"
                    reference="content/practice"
                    fullWidth
                    filter={{ isUserGeneratedContent: false }}
                  >
                    <LessonPracticeInput showDifficulties={false}>
                      <AutocompleteArrayInput
                        debounce={750}
                        optionValue="id"
                        optionText={(record: any) =>
                          `${record?.word} ${
                            record?.coachSample ? ` ✅` : ` ❌`
                          } (id: ${record?.id?.slice(0, 4)})`
                        }
                        allowDuplicates
                        matchSuggestion={(filterValue: string, choice: any) =>
                          matchSuggestion(filterValue, choice, 'word')
                        }
                      />
                    </LessonPracticeInput>
                  </ReferenceArrayInput>
                </Box>
              </Box>

              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                undoable={false}
                invalid={formProps.invalid}
                handleSubmit={formProps.handleSubmit}
                saving={formProps.saving}
                resource="multipleChoice"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  <SaveButton
                    label="Save & Continue"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect="edit"
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />

                  <SaveButton
                    label="Save & Exit"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect={formProps.redirect}
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />
                </div>

                {formProps.record &&
                  typeof formProps.record.id !== 'undefined' && (
                    <DeleteButton
                      basePath={formProps.basePath}
                      record={formProps.record}
                      resource={formProps.resource}
                      undoable={formProps.undoable}
                      mutationMode={formProps.mutationMode}
                    />
                  )}
              </Toolbar>
            </CardContent>
          </form>
        </Card>
      )}
    />
  );
};

const LessonEdit: FC<EditProps> = (props) => (
  <Edit undoable={false} title={<MultipleChoiceTitle />} {...props}>
    <MultipleChoiceForm />
  </Edit>
);

export default LessonEdit;
